import {debounce} from 'src/js/index';

((debounce) => {
    const sections = document.querySelectorAll('main > section');

    /**
     * Handle "onScreen" and "offScreen" classes and prev/next section arrows visibility
     * @param {NodeList} items node list of items to handle
     * @param {number} screenCover screen cover percent that triggers the change
     * @example onScreenHandler = (items, 0.5)
     *          onScreenHandler = (items)
     */
    const onScreenHandler = (items, screenCover = 0.1) => {
        const windowData = {
            windowTop: window.scrollY,
            windowBottom: window.scrollY + window.innerHeight,
            screenCoverSize: Math.round(window.innerHeight * screenCover),
        };
        const sectionsData = {
            onScreenSectionIndex: undefined,
            sectionsCount: items.length,
        };

        // Handle on/off-screen classes for sections
        [...items].forEach((item, i) => {
            const start = item.offsetTop + windowData.screenCoverSize;
            const end = item.offsetTop + item.offsetHeight - windowData.screenCoverSize;

            if (start < windowData.windowBottom && end > windowData.windowTop) {
                sectionsData.onScreenSectionIndex = i + 1;
                item.classList.add('on-screen');
                item.classList.remove('off-screen');
            } else {
                item.classList.remove('on-screen');
                item.classList.add('off-screen');
            }
        });

        // Handle prev/next section arrows visibility
        if (sectionsData.onScreenSectionIndex === 1) {
            document.querySelector('.section-toggle__prev').classList.add('off-screen');
            document.querySelector('.section-toggle__prev').setAttribute('aria-hidden', true);
            document.querySelector('.section-toggle__next').classList.remove('off-screen');
            document.querySelector('.section-toggle__next').setAttribute('aria-hidden', false);
        } else if (sectionsData.onScreenSectionIndex > 1 && sectionsData.onScreenSectionIndex < sectionsData.sectionsCount) {
            document.querySelector('.section-toggle__prev').classList.remove('off-screen');
            document.querySelector('.section-toggle__prev').setAttribute('aria-hidden', false);
            document.querySelector('.section-toggle__next').classList.remove('off-screen');
            document.querySelector('.section-toggle__next').setAttribute('aria-hidden', false);
        } else {
            document.querySelector('.section-toggle__prev').classList.remove('off-screen');
            document.querySelector('.section-toggle__prev').setAttribute('aria-hidden', false);
            document.querySelector('.section-toggle__next').classList.add('off-screen');
            document.querySelector('.section-toggle__next').setAttribute('aria-hidden', true);
        }
    };

    // Event handlers
    const onScrollHandler = debounce(() => {
        onScreenHandler(sections);
    });
    const onResizeHandler = debounce(() => {
        onScreenHandler(sections);
    });

    // Section toggles
    document.querySelector('.section-toggle__next').addEventListener('click', function (e) {
        e.preventDefault();
        const nodes = document.querySelectorAll('.on-screen');
        const first = nodes[0];
        first.nextElementSibling.scrollIntoView({behavior: 'smooth', block: 'start'});
    });
    document.querySelector('.section-toggle__prev').addEventListener('click', function (e) {
        e.preventDefault();
        const nodes = document.querySelectorAll('.on-screen');
        const last = nodes[nodes.length - 1];
        last.previousElementSibling.scrollIntoView({behavior: 'smooth', block: 'start'});
    });

    // Events
    window.addEventListener('scroll', function () {
        onScrollHandler();
    });
    window.addEventListener('load', function () {
        onScrollHandler();
        document.querySelector('body').classList.add('window-load');
    });
    window.addEventListener('resize', function () {
        onResizeHandler();
    });
    document.addEventListener('DOMContentLoaded', function () {
        onScrollHandler();
        document.querySelector('body').classList.add('dom-ready');
    });
})(debounce);
