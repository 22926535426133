// Import CSS
import '../css/index.scss';
import '../css/tailwind.css';

/**
 * Debouncer function constructor
 * @param {function} func function to debounce
 * @param {number} wait debouncer delay
 * @returns {function} debouncer function
 */
export const debounce = (func, wait = 100) => {
    let waiting = false;
    return function () {
        if (waiting) {
            return;
        }

        waiting = true;
        setTimeout(() => {
            func.apply(this, arguments);
            waiting = false;
        }, wait);
    };
};
