/* global grecaptcha */
window.grecaptchaLoaded = false;
window.formValidated = false;

const createConsentCookie = (days = 365) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + date.toUTCString();
    document.cookie = 'cookieConsent=true; ' + expires + '; path=/; SameSite=Strict';
};

const hasConsentCookie = () => {
    return document.cookie.split(';').some((item) => item.trim().startsWith('cookieConsent='));
};

const contactLoader = (status) => {
    if (status) {
        document.querySelector('.img-contact--normal').style.visibility = 'hidden';
        document.querySelector('.img-contact--loading').style.visibility = 'visible';
    } else {
        document.querySelector('.img-contact--normal').style.visibility = 'visible';
        document.querySelector('.img-contact--loading').style.visibility = 'hidden';
    }
};

const enableForm = () => {
    // Remove consent button
    document.querySelector('.accept-cookies--js').outerHTML = '';

    // Add reCaptcha
    var script = document.createElement('script');
    script.onload = function () {
        grecaptcha.ready(function () {
            // Set flag
            window.grecaptchaLoaded = true;

            // Enable for items
            [...document.querySelectorAll('.contact-form--js input:disabled, .contact-form--js textarea:disabled')].forEach((item) => {
                item.disabled = false;
            });
        });
    };
    script.src = 'https://www.google.com/recaptcha/api.js?render=6LdaJf4pAAAAAONZYcnbM867whtng2N86YU29PN8';
    document.head.appendChild(script);

    // Handle submit button
    document.querySelector('.send-email--js').style.removeProperty('display');
    [...document.querySelectorAll('.contact-form--js input, .contact-form--js textarea')].forEach((item) => {
        item.addEventListener('keyup', () => {
            window.formValidated =
                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(document.querySelector('.contact-at--js').value) &&
                document.querySelector('.contact-title--js').value &&
                document.querySelector('.contact-at--js').value &&
                document.querySelector('.contact-msg--js').value
                    ? true
                    : false;

            window.formValidated && window.grecaptchaLoaded
                ? (document.querySelector('.send-email--js').disabled = false)
                : (document.querySelector('.send-email--js').disabled = true);
        });
    });
    document.querySelector('.send-email--js').addEventListener('click', async () => {
        contactLoader(true);
        document.querySelector('.contact-form-send--js').style.display = 'none';
        document.querySelector('.contact-form-error--js').style.display = 'none';

        try {
            const token = await grecaptcha.execute('6LdaJf4pAAAAAONZYcnbM867whtng2N86YU29PN8', {action: 'submit'});
            const response = await fetch('/mail.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    title: document.querySelector('.contact-title--js').value,
                    author: document.querySelector('.contact-at--js').value,
                    content: document.querySelector('.contact-msg--js').value,
                    response: token,
                }),
            });
            const responseData = await response.json();

            if (responseData?.success) {
                document.querySelector('.contact-form-error--js').style.display = 'none';
                document.querySelector('.contact-form-send--js').style.display = 'block';
                document.querySelector('.contact-form--js').outerHTML = '';
            } else {
                document.querySelector('.contact-form-send--js').style.display = 'none';
                document.querySelector('.contact-form-error--js').style.display = 'block';
            }
        } catch (err) {
            document.querySelector('.contact-form-send--js').style.display = 'none';
            document.querySelector('.contact-form-error--js').style.display = 'block';
        }

        contactLoader(false);
    });
};

(() => {
    // Bind consent cookie accept button
    document.querySelector('.accept-cookies--js').addEventListener('click', () => {
        createConsentCookie();
        enableForm();
    });

    // Init form if cookie present
    if (hasConsentCookie()) {
        enableForm();
    }
})();
